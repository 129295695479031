
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
    },
})
export default class Menu extends Vue {
  isOpenMenu: boolean;
  isTabsIdxOptional: boolean;

  constructor() {
    super();
    this.isOpenMenu = false;
    this.isTabsIdxOptional = false;
  }

  get menu() {
    return [
      {
        label: "Login",
        route: "/login",
        name: "login"
      },
      // {
      //   label: "Register",
      //   route: "/register",
      //   name: "register"
      // }
    ];
  }

  get tabIdx(): number {
    const matchIdx = this.menu.findIndex(ele => ele.route === this.$route.path);
    if (matchIdx === -1) {
      this.isTabsIdxOptional = true;
    } else {
      this.isTabsIdxOptional = false;
    }
    return matchIdx;
  }

  handleChange(idx: number) {
    if (idx !== undefined && this.$route.path !== this.menu[idx].route) {
      this.$router.push(this.menu[idx].route);
    }
  }
}
