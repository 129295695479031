import { ELevelSlug, SavePeriod, PeriodFilter } from "@/shared/api-interfaces";
import { AxiosInstance } from "axios";

const resource = "/license/period";

export default function(configedRequest: AxiosInstance) {
  return {
    async get() {
      const { data } = await configedRequest.get(`${resource}`);
      return data;
    },
    async save(body: SavePeriod) {
      const { data } = await configedRequest.post(`${resource}`, body);
      return data;
    },
    async update(body: any) {
      const { data } = await configedRequest.post(`${resource}/update`, body);
      return data;
    },
    async delete(body: any) {
      const { data } = await configedRequest.post(`${resource}/delete`, body);
      return data;
    },

    async getPeriodYear() {
      const { data } = await configedRequest.get(`${resource}/period-year`);
      return data;
    },
    async getPeriodNext(params?: PeriodFilter) {
      const { data } = await configedRequest.get(`${resource}/period-next`, {
        params
      });
      return data;
    },
    async getPeriodAll(params?: any) {
      const { data } = await configedRequest.get(`${resource}/all`, {
        params
      });
      return data;
    },
    async checkCurrentPeriod() {
      const { data } = await configedRequest.get(`${resource}/period-event`);
      return data;
    }
  };
}
