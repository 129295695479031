import { ActionTree, Module, MutationTree } from "vuex";

const state: GlobalState = {
  isShowErrorSnackbar: false,
  errorText: "",
  alertText: "",
  isShowAlert: false,
  language: "TH",
  viewHistory: {
    isViewHistory: false,
    periodStart: "",
    periodEnd: ""
  }
};

const mutations: MutationTree<GlobalState> = {
  setErrorText(state, value) {
    state.errorText = value;
  },
  setShowErrorSnackbar(state, value) {
    state.isShowErrorSnackbar = value;
  },
  setAlertText(state, value) {
    state.alertText = value;
  },
  setShowAlertSnackbar(state, value) {
    state.isShowAlert = value;
  },
  setLanguage(state, value) {
    state.language = value;
  },
  setViewHistory(state, payload) {
    state.viewHistory = payload;
  }
};

const actions: ActionTree<GlobalState, {}> = {
  showErrorSnackbar({ commit }, text: string) {
    commit("setErrorText", text);
    commit("setShowErrorSnackbar", true);
  },
  closeErrorSnackbar({ commit }) {
    commit("setShowErrorSnackbar", false);
  },
  showAlertSnackbar({ commit }, text: string) {
    commit("setAlertText", text);
    commit("setShowAlertSnackbar", true);
  },
  closeAlertSnackbar({ commit }) {
    commit("setShowAlertSnackbar", false);
  },
  changeLanguage({ commit }, text: string) {
    // if(text === 'TH')
    commit("setLanguage", text);
  },
  viewHistory(
    { commit },
    value: {
      isViewHistory: boolean;
      periodStart: string | null;
      periodEnd: string | null;
    }
  ) {
    commit("setViewHistory", value);
  }
};

export const global: Module<GlobalState, {}> = {
  state,
  mutations,
  actions
};

interface GlobalState {
  isShowErrorSnackbar: boolean;
  errorText?: string;
  alertText?: string;
  isShowAlert: boolean;
  language: string;
  viewHistory?: {
    isViewHistory: boolean;
    periodStart: string;
    periodEnd: string;
  };
}
