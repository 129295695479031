import { Account } from "@/shared/api-interfaces"
import { ActionTree, Module, MutationTree } from "vuex"


const state: UserState = {
    account: null,
    page_category: 1,
}

const mutations: MutationTree<UserState> = {
  setUser(state, user: Account | null) {
    state.account = user;
    },
    setPageCategory(state, category) {
        state.page_category = category;
  }
}

const actions: ActionTree<UserState, {}> = {
  setUserDetail({commit}, user: Account) {
    commit('setUser', user);
  },
  clearUserDetail({commit}) {
    commit('setUser', null);
    },
    changePageCategory({ commit }, category: number) {
        commit('setPageCategory', category)
  }
}


export const user: Module<UserState, {}> = {
  state,
  mutations,
  actions
}

interface UserState {
  account: Account | null;
    page_category: number | null;
}