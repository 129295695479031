import {
  ELevelSlug,
  PaginateResult,
  ResponseSuccess,
  LicenseLevel
} from "@/shared/api-interfaces";
import { AxiosInstance } from "axios";

const resource = "/license/level";
const period = "/license/period";

export default function(configedRequest: AxiosInstance) {
  return {
    async get() {
      const { data } = await configedRequest.get<
        ResponseSuccess<PaginateResult<LicenseLevel>>
      >(`${resource}`);
      return data;
    },
    async changeStatus(levelId: string, active: boolean) {
      const {
        data
      } = await configedRequest.patch(`${resource}/${levelId}/active`, {
        active
      });
      return data;
    },
    async getBySlug(slug: ELevelSlug) {
      const { data } = await configedRequest.get<ResponseSuccess<LicenseLevel>>(
        `${resource}/${slug}`
      );
      return data;
    },
    async checkAvailable() {
      const { data } = await configedRequest.get(`${period}/available`);
      return data;
    }
  };
}