import { DefaultResponse, Dashboard } from "@/shared/api-interfaces";
import { AxiosInstance } from "axios";

const url = "/dashboard";

export default function(configedRequest: AxiosInstance) {
  return {
    async get() {
      const { data } = await configedRequest.get(`${url}/license-year`);
      return data;
    },
    async getThailandMap(params: any) {
      const { data } = await configedRequest.get(
        `${url}/find-academy-province`,
        { params }
      );
      return data;
    },
    async getLicenseFilter(params: any) {
      const { data } = await configedRequest.get(`${url}/license`, { params });
      return data;
    },
    async getListLicense(params: any) {
      const { data } = await configedRequest.get(`${url}/license-year-lists`, {
        params
      });
      return data;
    },
    async getLicenseByYear(params: any) {
      const { data } = await configedRequest.get(
        `${url}/license-month-by-year/${params.year}`
      );
      return data;
    },
    async getLicenseByMonth(params: any) {
      const { data } = await configedRequest.get(`${url}/license-monthly`, {
        params
      });
      return data;
    },
    async getDownloadFile(params: any) {
      const {
        data
      } = await configedRequest.get(`${url}/license-year-lists/export`, {
        params,
        responseType: "blob",
      });
      return data;
    }
  };
}
