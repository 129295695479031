import { AdminPermission } from "@/store/auth";

export function checkPermission(route: string, permission: AdminPermission) {
  // return true;
  if (route.includes("dashboard") && permission.academy) {
    return true;
  }
  if (route.includes("academy") && permission.academy) {
    return true;
  }
  if (route.includes("period") && permission.setting) {
    return true;
  }
  if (route.includes("level") && permission.setting) {
    return true;
  }
  if (route.includes("category") && permission.setting) {
    return true;
  }
  if (route.includes("activity") && permission.academy) {
    return true;
  }

  return false;
}
