
import { Component, Vue, Prop } from "vue-property-decorator";
import Logo from "@/assets/icons/Logo.vue";
import Menu from "./Menu.vue";
import LanguageDropdown from "./LanguageDropdown.vue";
import CurrentUser from "@/shared/components/CurrentUser.vue";
import Notification from "@/shared/components/Notification.vue";
import UnauthMenu from "./UnauthMenu.vue";
import { appRoute } from "@/shared/constants";
import { AppApiFactory } from "@/services/AppFactory";
import { NotificationMessage } from "@/shared/api-interfaces";

const messageService = AppApiFactory.message;

@Component({
  components: {
    Logo,
    Menu,
    LanguageDropdown,
    CurrentUser,
    Notification,
    UnauthMenu
  }
})
export default class Header extends Vue {
  @Prop() logoPath!: string;
  notiList: NotificationMessage[];

  localKey: string;
  count: number;

  constructor() {
    super();
    this.localKey = process.env.VUE_APP_ADMIN_KEY;
    this.notiList = [];
    this.count = 0;
  }

  showLangDropdown = false;

  handleClickIcon() {
    this.$router.push(appRoute.home, () => ({}));
  }

  mounted() {
    this.getNotificaiton();
    // console.log();
  }

  private async getNotificaiton() {
    messageService.getNotification().then(res => {
      this.notiList = res.data.result;
      this.count = res.data.count;
    });
  }

  onHoverMessage(messageId: string) {
    messageService.setIsRead(messageId).then(() => {
      this.getNotificaiton();
    });
  }

  get isAuth() {
    return this.$store.state.auth.isAppAuthen;
  }
}
