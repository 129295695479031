import { DefaultResponse } from "@/shared/api-interfaces";
import { AcademyProfile } from "@/shared/types/profile";
import { AxiosInstance } from "axios";

const resource = "/profile";
const global = "/global/regions"

export default function (configedRequest: AxiosInstance) {
  return {
      async get(): Promise<DefaultResponse<any>> {
      const { data } = await configedRequest.get(`${resource}/me`);
      return data;
      },
      async getProvince(): Promise<DefaultResponse<any>> {
          const { data } = await configedRequest.get(`${global}/provinces`);
          return data;
      },
      async getDistrict(id: any): Promise<DefaultResponse<any>> {
          const { data } = await configedRequest.get(`${global}/district/${id}`);
          return data;
      },
      async getSubDistrict(id: any): Promise<DefaultResponse<any>> {
          const { data } = await configedRequest.get(`${global}/sub-district/${id}`);
          return data;
      },
      async getHeadOfAcademy(): Promise<DefaultResponse<any>> {
        const { data } = await configedRequest.get(`${resource}/head-of-academy`);
        return data;
    },
  };
}
