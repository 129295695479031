import { ResponseSuccess, SignIn, SigninResponse } from "@/shared/api-interfaces";
import { appRequest } from "./config";

const resource = "/authentication";

export default {
  login(body: SignIn) {
    return appRequest.post<ResponseSuccess<SigninResponse>>(`${resource}/sign-in`, body);
  }
};
