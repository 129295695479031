//import { ELevelSlug, SavePeriod } from "@/shared/api-interfaces";
import { AxiosInstance } from "axios";

const resource = "/account/personnels";

export default function(configedRequest: AxiosInstance) {
  return {
    async get() {
      const { data } = await configedRequest.get(`${resource}`);
      return data;
    },
    async save(body: any) {
      const { data } = await configedRequest.post(`${resource}`, body);
      return data;
    }
  };
}
