
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
    },
})
export default class LanguageDropdown extends Vue {
  data() {
    return {
      selected_tags: []
    };
  }
  open_menu = false;

  diffLang = "TH";
  languageItems = [
    { name: "TH", image: require("@/assets/flag-th.png") },
    { name: "EN", image: require("@/assets/flag-en.png") }
  ];
  get currentLang() {
    return this.$store.state.global.language;
  }
  get checkDropdown() {
    return this.languageItems.filter(
      (lang: any) => lang.name !== this.currentLang
    );
  }
  get lang() {
    return localStorage.getItem("lang");
  }

  get menu_dropdown() {
    return true;
  }

  open_menu_select() {
    this.open_menu = !this.open_menu;
  }
  selectLang(value: any) {
    localStorage.setItem("lang", value);
    this.$store.dispatch("changeLanguage", value);
    this.open_menu = !this.open_menu;
  }
}
