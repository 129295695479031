
import { Vue, Component } from "vue-property-decorator";
import ErrorSnackbar from "@/shared/components/ErrorSnackbar.vue";
import AlertSnackbar from "./shared/components/AlertSnackbar.vue";

@Component({
  components: {
    ErrorSnackbar,
    AlertSnackbar
  }
})
export default class Site extends Vue {}
