import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#0B3C5D",
        error: "#777",
        cyan: "#74BECA",
        lightcyan: "#EBF6F7",
        lightpurple: "#9499CC",
        darkgreen: "#44909C",
        darkyellow: "#E9BC66"
      }
    }
  }
});
