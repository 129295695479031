import { ActionTree, Commit, GetterTree, Module, MutationTree } from "vuex";
import { UpdateAccount } from "@/shared/api-interfaces";
import jwtDecode from "jwt-decode";

const state: AuthState = {
    isAdminAuthen: false,
    isAppAuthen: false,
    permission: {
        adminPermission: undefined,
    },
    isFirstTime: true
};

const getters: GetterTree<AuthState, {}> = {
    isAppAuthen(state) {
        return state.isAppAuthen
    },
    isAdminAuthen(state) {
        return state.isAdminAuthen;
    },
    adminPermission(state) {
        return state.permission.adminPermission;
    },
    isFirstTime(state) {
        return state.isFirstTime;
    }
}

const mutations: MutationTree<AuthState> = {
    setAdminAuth(state, input: boolean) {
        state.isAdminAuthen = input;
    },
    setAppAuth(state, input: boolean) {
        state.isAppAuthen = input;
    },
    setAdminPermission(state, input: AdminPermission) {
        state.permission.adminPermission = input;
    },
    setFirstTime(state, input: boolean) {
        state.isFirstTime = input;
    }
};

function checkAuth(localStorageKey: string, mutation: string, commit: Commit, permissionMutations?: string) {
    const token = localStorage.getItem(localStorageKey);
    if (token) {
        const decodedToken = jwtDecode<Token>(token);
        const isTokenExpire = (decodedToken.exp * 1000) <= Date.now();
        if (!isTokenExpire) {
            commit(mutation, true);
            if (permissionMutations) {
                commit(permissionMutations, decodedToken.account_role.permission_menu);
            }
            return;
        }
    }
    commit(mutation, false);
    window.location.href = process.env.VUE_APP_SSO_PATH;
}

const actions: ActionTree<AuthState, {}> = {
    checkAdminAuth({ commit, state }) {
        if (state.isAdminAuthen) return;
        checkAuth(process.env.VUE_APP_ADMIN_KEY, "setAdminAuth", commit, 'setAdminPermission');
    },
    checkAppAuth({ commit }) {
        if (state.isAppAuthen) return;
        checkAuth(process.env.VUE_APP_APP_KEY, "setAppAuth", commit);
    },
    removeAdminAuth({ commit }) {
        commit("setAdminAuth", false);
        checkAuth(process.env.VUE_APP_ADMIN_KEY, "setAdminAuth", commit);
    },
    removeAppAuth({ commit }) {
        commit("setAdminAuth", false);
        checkAuth(process.env.VUE_APP_ADMIN_KEY, "setAppAuth", commit);
    },
    checkFirstTime({ commit }, value: boolean) {
        commit("setFirstTime", value);
    }
};

export const auth: Module<AuthState, {}> = {
    state,
    mutations,
    getters,
    actions,
};

export interface Token {
    name: string;
    id: string;
    account_type: number;
    account_role: {
        active: boolean;
        created_at: string;
        created_by: string;
        updated_at: string;
        updated_by: string;
        id: string;
        roles: string;
        permission_code: string;
        permission_name_th: string;
        permission_name_en: string;
        permission_menu: AdminPermission;
        deleted: boolean;
    };
    iat: number;
    exp: number;
}

export interface Permission {
    adminPermission?: AdminPermission;
}

export interface AdminPermission {
    user: boolean;
    academy: boolean;
    setting: boolean;
    activity: boolean;
    dashboard: boolean;
    user_edit: boolean;
    academy_edit: boolean;
    setting_edit: boolean;
    activity_edit: boolean;
    dashboard_edit: boolean;
    license_approves: boolean;
    document_approves: boolean;
    license_approves_edit: boolean;
    document_approves_edit: boolean;
}

interface AuthState {
    isAdminAuthen: boolean;
    isAppAuthen: boolean;
    user?: UpdateAccount;
    permission: Permission;
    isFirstTime?: boolean;
}
