import {
    DefaultResponse,
    PaginateResult, SearchCategory, CategoryBody
} from "@/shared/api-interfaces";
import { AxiosInstance } from "axios";

const url = "/category";

export default function (configedRequest: AxiosInstance) {
    return {
        async get(params?: SearchCategory): Promise<DefaultResponse<PaginateResult<any>>> {
            const { data } = await configedRequest.get<any>(`${url}`, { params });
            return data;
        },
        async patch(categoryId: string, active: any) {
            const { data } = await configedRequest.patch(`${url}/${categoryId}/active`, active);
            return data;
        },
        async put(categoryId: string | number, body: CategoryBody) {
            const { data } = await configedRequest.put(`${url}/${categoryId}`, body);
            return data
        },
        async getById(categoryId: string | number) {
            const { data } = await configedRequest.put(`${url}/${categoryId}`);
            return data
        }
        // get() {
        //   return configedRequest.get<
        //     ResponseSuccess<{ licenseLevel: LicenseLevel[] }>
        //   >(`${resource}`);
        // },
        // getBySlug(slug: ELevelSlug) {
        //   return configedRequest.get<
        //     ResponseSuccess<{ licenseLevel: LicenseLevel[] }>
        //   >(`${resource}`, { params: { license_slug: slug } });
        // }
    };
}