
import { Component, Vue, Prop } from "vue-property-decorator";
import jwtDecode from "jwt-decode";
import { appRoute } from "@/shared/constants";
import { AppApiFactory } from "@/services/AppFactory";

const profileService = AppApiFactory.profile;

@Component({
  components: {}
})
export default class CurrentUser extends Vue {
  @Prop() localStorageKey!: string;
  @Prop() action!: string;
  @Prop() authStateKey!: string;
  image: string;

  constructor() {
    super();
    this.image = "";
  }

  mounted() {
    this.getAcademyProfile();
  }

  getAcademyProfile() {
    profileService.get().then(res => {
      this.image = res.data.image;
    });
  }

  async handleLogout() {
    await localStorage.removeItem(this.localStorageKey);
    await localStorage.removeItem("userLicenseId");
    this.$store.dispatch(this.action).then(() => {
      if (this.$store.getters[this.authStateKey]) {
        window.location.href = process.env.VUE_APP_SSO_PATH;
      }
    });
  }

  editInformation() {
    if (this.$store.state.auth.isFirstTime) {
      this.$router.push(appRoute.register, () => ({}));
    } else {
      this.$router.push(appRoute.editProfile, () => ({}));
    }
  }

  get isAuth() {
    return this.$store.state.auth[this.authStateKey];
  }

  get isFirstTime() {
    return this.$store.state.auth.isFirstTime;
  }
  get userName() {
    const token = localStorage.getItem(process.env.VUE_APP_APP_KEY);
    if (token) {
      const decodedToken: any = jwtDecode(token);

      return decodedToken?.name;
    }
    return null;
  }

  get lang() {
    return this.$store.state.global.language;
  }
}
