import { ResponseSuccess } from "@/shared/api-interfaces";
import { Account } from "@/shared/api-interfaces";
import { License } from "@/shared/types/license";
import { AxiosInstance } from "axios";

const resource = "/account";

export default function (configedRequest: AxiosInstance) {
    return {
      get() {
        return configedRequest.get<ResponseSuccess<{ account: Account }>>(
          `${resource}`
        );
      },
      async getLicense() {
        const { data } = await configedRequest.get<ResponseSuccess<License>>(
          `license/user`
        );
        return data;
      },
      async getAllLicense() {
        const { data } = await configedRequest.get(`license/user/all`);
        return data;
      },
      async register(body: any) {
        const { data } = await configedRequest.post(`/profile`, body);
        return data;
      },
      async previewBook(size: "mini" | "full", lang: "th" | "en") {
        const { data } = await configedRequest.get<
          ResponseSuccess<{ filename: string }>
        >("pdf/preview", { params: { size, lang } });
        return data;
      },
      async getYearCerificate() {
        const { data } = await configedRequest.get(
          `/license/user/year-approved-license`
        );
        return data;
      },
      async getCerificateByYear(year: number) {
        const { data } = await configedRequest.get(
          `/license/user/license-year-filter?year=${year}`
        );
        return data;
      }
    };
};
