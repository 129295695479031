
import { Vue, Component } from "vue-property-decorator";
import Header from "@/shared/components/layout/app/Header/Header.vue";
import store from "@/store";
import { appRoute } from "@/shared/constants";

@Component({
  components: {
    Header
  },
  store: store
})
export default class App extends Vue {
  appPath: string;

  constructor() {
    super();
    this.appPath = appRoute.home;
  }

  created() {
    this.$store.dispatch("checkAppAuth").then(() => {
      if (this.$store.state.auth.isAppAuthen) {
        // userService.get().then(res => {
        //   this.$store.dispatch('setUserDetail', res.data.data.account);
        // });
      }
    });
  }
}
