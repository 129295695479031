import {
  DefaultResponse,
  EActivityCategory,
  Activity,
  PaginateResult,
  SearchPaginate,
} from "@/shared/api-interfaces";
import { AxiosInstance } from "axios";

const url = "/activity";

export default function(configedRequest: AxiosInstance) {
  return {
    async get(
      category: EActivityCategory,
      params?: SearchPaginate
    ): Promise<DefaultResponse<PaginateResult<Activity>>> {
      const { data } = await configedRequest.get<
        DefaultResponse<PaginateResult<Activity>>
      >(`${url}/${category}`, { params });
      return data;
    }
  };
}
