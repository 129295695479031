export const appRoute: MenuRoute = {
  register: "/register",
  editProfile: "/edit-profile",
  home: "/",
  licenseSelect: "select-license",
  applyLicense: "apply-license",
  guidelines: "guidelines",
  leadership: "leadership",
  planning: "planning",
  organization: "organization",
  staffing: "staffing",
  recruitment: "recruitment",
  finance: "finance",
  facilities: "facilities",
  teams: "teams",
  coaching: "coaching",
  playing: "playing",
  playerPerformance: "player_performance",
  health: "health",
  fitness: "fitness",
  psychology: "psychology",
  welfare: "welfare",
  education: "education",
  collaboration: "collaboration",
  assessment: "assessment",
  rules: "rules",
  results: "results",
  history: "history",
  member: "member",
  certificate: "certificate",
  book: "book"
};

interface MenuRoute {
  register: string;
  editProfile: string;
  licenseSelect: string;
  home: string;
  applyLicense: string;
  guidelines: string;
  leadership: string;
  planning: string;
  organization: string;
  staffing: string;
  recruitment: string;
  finance: string;
  facilities: string;
  teams: string;
  coaching: string;
  playing: string;
  playerPerformance: string;
  health: string;
  fitness: string;
  psychology: string;
  welfare: string;
  education: string;
  collaboration: string;
  assessment: string;
  rules: string;
  results: string;
  history: string;
  member: string;
  certificate: string;
  book: string;
}
