import { NotificationMessage, ResponseSuccess } from "@/shared/api-interfaces";
import { AxiosInstance } from "axios";

const resource = "/firebase";

export default function (configedRequest: AxiosInstance) {
  return {
    async saveToken(token: string) {
      const { data } = await configedRequest.post(
        `${resource}/save-token`,
        { token }
      );
      return data;
    },
    async getNotification(): Promise<ResponseSuccess<{ result: NotificationMessage[]; count: number }>> {
      const { data } = await configedRequest.get(`${resource}/message`);
      return data;
    },
    async setIsRead(messageId: string) {
      const { data } = await configedRequest.put(`${resource}/message/${messageId}`);
      return data;
    }
  };
}
