import { ResponseSuccess, Roles } from "@/shared/api-interfaces";
import { AxiosInstance } from "axios";

const resource = "/roles";

export default function(configedRequest: AxiosInstance) {
  return {
    get() {
      return configedRequest.get<ResponseSuccess<{ role: Roles[] }>>(
        `${resource}`
      );
    },
    getByRoleCode(roleCode: string) {
      return configedRequest.get<ResponseSuccess<{ role: Roles }>>(
        `${resource}/${roleCode}`
      );
    }
  };
}
