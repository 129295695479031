import { UpdateLicenseStatus } from "@/shared/api-interfaces";
import { AxiosInstance } from "axios";

const resource = "/license/user";

export default function(configedRequest: AxiosInstance) {
  return {
    async changeStatus(userLicenseId: string, body: UpdateLicenseStatus) {
      const { data } = await configedRequest.put(
        `${resource}/${userLicenseId}`,
        body
      );
      return data;
    }
  };
}
