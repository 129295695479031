import { AxiosInstance } from "axios";

const url = "/excel";
type DataBody = {
  categories: string[];
  accounts: string[];
};

export default function(configedRequest: AxiosInstance) {
  return {
    async getAcademy() {
      const { data } = await configedRequest.get<any>(`${url}/academy`);
      return data;
    },
    async getAcademyApproved() {
      const { data } = await configedRequest.get<any>(
        `${url}/academy/approved`
      );
      return data;
    },
    async getAcademyApplied() {
      const { data } = await configedRequest.get<any>(`${url}/academy/applied`);
      return data;
    },
    async getDownloadAcademyLicense() {
      const { data } = await configedRequest.get<any>(
        `${url}/academy-download/approved`
      );
      return data;
    },
    async getDownloadAcademyCriteria() {
      const { data } = await configedRequest.get<any>(
        `${url}/academy-download/applied`
      );
      return data;
    },
    async saveAcademyGenerator(
      licenseLevel: string,
      dates: string[],
      status: string,
      filename: string,
      body: DataBody
    ) {
      const { data } = await configedRequest.post(
        `${url}/academy-generator?license_level=${licenseLevel}&period_start=${dates[0]}&period_end=${dates[1]}${status}&filename=${filename}`,
        body
      );
      return data;
    },
    async saveAcademyGeneratorApproved(
      licenseLevel: string,
      dates: string[],
      status: string,
      filename: string,
      body: DataBody
    ) {
      const { data } = await configedRequest.post(
        `${url}/academy-generator/approved?${
          licenseLevel.length > 0 ? `license_level=${licenseLevel}` : ""
        }${dates.length > 0 ? `&period_start=${dates[0]}&period_end=${dates[1]}` : ""
        }${status ? `&status=${status}` : ""}&filename=${filename}`,
        body
      );
      return data;
    },
    async saveAcademyGeneratorApplied(
      licenseLevel: string,
      dates: string[],
      status: string,
      filename: string,
      body: DataBody
    ) {
      const { data } = await configedRequest.post(
        `${url}/academy-generator/applied?${
          licenseLevel.length > 0 ? `license_level=${licenseLevel}` : ""
        }${dates.length > 0 ? `&period_start=${dates[0]}&period_end=${dates[1]}` : ""
        }${status ? `&status=${status}` : ""}&filename=${filename}`,
        body
      );
      return data;
    },
    async academyCancel(id: string) {
      const { data } = await configedRequest.post(
        `${url}/academy-generator/${id}/cancel`
      );
      return data;
    }
  };
}
