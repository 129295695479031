
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import { NotificationMessage } from "../api-interfaces";
import { format } from "date-fns";
import { AppApiFactory } from "@/services/AppFactory";

const userService = AppApiFactory.user;

@Component({
  components: {}
})
export default class Notification extends Vue {
  @Prop({ default: [] }) notiList!: NotificationMessage[];
  @Prop({ default: 0 }) count!: number;

  selected: number[];
  level: string;

  constructor() {
    super();
    this.selected = [];
    this.level = "";
  }

  @Emit()
  readMessage(messageId: string) {
    return messageId;
  }

  onHoverItem(messageId: string, isRead: boolean, notiMessage: any) {
    // console.log('hover',notiMessage,this.$route,this.level)
    // this.$router.push(`/${this.level}/apply-license/${notiMessage.category.toLowerCase()}`)
    if (!this.$route.path.includes("admin")) {
      this.$router.push(
        `/${this.level}/apply-license/${notiMessage.category.toLowerCase()}`
      );
    } else {
      this.$router.push(`/admin/academy/${this.level}/${notiMessage.user_id}`);
    }
    if (isRead) return;
    this.readMessage(messageId);
    this.changeSelected();
  }

  get badgeColor() {
    return this.notiList && this.notiList.filter(ele => !ele.isRead).length > 0
      ? "red"
      : "transparent";
  }

  get lang() {
    return this.$store.state.global.language;
  }

  get messageList() {
    if (!this.notiList) return [];
    return this.notiList.map(ele => ({
        user_id:ele?.information?.userlicense_id || '',
    category: ele?.information?.category_slug || ele?.information?.category_name || '',
      message: this.lang === 'EN'?ele.message.message:ele.message.message_th,
      date: format(new Date(ele.created_at), "dd/MM/yy HH:mm"),
      isRead: ele.isRead,
      id: ele._id
    }));
  }

  getLevels() {
    userService.getLicense().then(res => {
      this.level = res.data?.license_level?.license_slug?.toLowerCase();
    });
  }

  mounted() {
    this.getLevels();
  }

  @Watch("notiList")
  notiListChange() {
    this.changeSelected();
  }

  private changeSelected() {
    this.selected = this.notiList
      .filter(ele => !ele.isRead)
      .map((ele, idx) => idx);
  }
}
