
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
    },
})
export default class ErrorSnackbar extends Vue {
  get isShowSnackbar() {
    return this.$store.state.global.isShowErrorSnackbar;
  }

  set isShowSnackbar(value) {
    if (!value) {
      this.$store.dispatch("closeErrorSnackbar");
    }
  }

  get snackbarText() {
    return this.$store.state.global.errorText;
  }
}
