import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
// import { key } from "./rsa";
import store from "@/store";

export class Request {
  private baseURL: string;
  private _request: AxiosInstance;

  constructor() {
    this.baseURL = "/api/v1";
    this._request = axios.create({
      baseURL: this.baseURL
    });
  }

  create(localKey: string, action: string) {
    this._request.interceptors.request.use(this.reqInterceptor(localKey));
    this._request.interceptors.response.use(
      res => res,
      this.errorResInterceptor(localKey, action)
    );
    return this._request;
  }

  private reqInterceptor = (localKey: string) => (
    req: AxiosRequestConfig
  ): AxiosRequestConfig => {
    // req.headers["rsa-ssl"] = key;
    if (localStorage.getItem(localKey)) {
      req.headers.Authorization = `Bearer ${localStorage.getItem(localKey)}`;
    }
    return req;
  };

  private errorResInterceptor = (localKey: string, action: string) => async (
    err: any
  ) => {
    let message: string | undefined;
    switch (err.response.status) {
      case 400:
        message = err.response.data.message ? err.response.data.message : "Bad request";
        break;
      case 401:
        await localStorage.removeItem(localKey);
        store.dispatch(action);
        break;
      case 404:
        message = "Not found";
        break;
      case 413:
        message = "File's size too large";
        break;
      default:
        message = "Something error please contact admin";
        break;
    }

    if (message) {
      store.dispatch("showErrorSnackbar", message);
    }
    throw err.response;
  };
}
