import {
  CodiumStaff,
  PaginateResult,
  SearchAccountParams
} from "@/shared/api-interfaces";
import { AxiosInstance } from "axios";

const url = "/codium";

export default function (configedRequest: AxiosInstance) {
  return {
    async getStaff(params?: SearchAccountParams): Promise<PaginateResult<CodiumStaff>> {
      const { data } = await configedRequest.get<any>(`${url}/staff`, { params });
      return data;
    }
  };
}
