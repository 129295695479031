import Auth from "./auth";
import { adminRequest, appRequest } from "./config";
import User from "./user";
import Level from "./level";
import Role from "./role";
import Category from "./category";
import Criteria from "./criteria";
import FileService from "./file";
import Period from "./period";
import License from "./license";
import Message from "./message";
import Activity from "./activity";
import Codium from "./codium";
import Profile from "./profile";
import Dashboard from "./dashboard";
import Member from "./member";
import Excel from "./excel";

export const AppApiFactory = {
  auth: Auth,
  user: User(appRequest),
  period: Period(appRequest),
  category: Category(appRequest),
  criteria: Criteria(appRequest),
  file: FileService(appRequest),
  message: Message(appRequest),
  license: Level(appRequest),
  codium: Codium(appRequest),
  profile: Profile(appRequest),
  member: Member(appRequest)
};

export const AdminApiFactory = {
  user: User(adminRequest),
  level: Level(adminRequest),
  role: Role(adminRequest),
  category: Category(adminRequest),
  criteria: Criteria(adminRequest),
  file: FileService(adminRequest),
  period: Period(adminRequest),
  license: License(adminRequest),
  message: Message(adminRequest),
  activity: Activity(adminRequest),
  dashboard: Dashboard(adminRequest),
  excel: Excel(adminRequest)
};
