import {
  ChangeApplylicenseStatus, ResponseSuccess,
  SaveApplyLicense,
  SaveUserLicense,
  SearchPaginate
} from "@/shared/api-interfaces";
import { AxiosInstance } from "axios";
import FileService from "./file";
import { License } from "@/shared/types/license";

const url = "/license/criteria";
const applyLicenseUrl = "apply-license";

export default function(configedRequest: AxiosInstance) {
  const fileService = FileService(configedRequest);
  return {
    async getBody(
      body: Record<
        string,
        string | File | Array<Record<string, string | File>>
      >,
      service: any
    ) {
      const parseBody = JSON.parse(JSON.stringify(body));
      const bodyKeys = Object.keys(body);
      if (body instanceof File) {
        //console.log("type file", body);
        return await service.save(body).then((res: any) => {
          const data = res.data;
          const result = {
            originalName: data.originalname,
            path: data.path,
            savedName: data.filename
          };
          return result;
        });
      } else {
        await Promise.all(
          bodyKeys.map(async bodyKey => {
            // console.log('key name', bodyKey, typeof body[bodyKey])
            if (typeof body[bodyKey] === "string") {
              // console.log('type string')
              return;
            }
            // console.log('type array', bodyKey, Array.isArray(body[bodyKey]))
            if (Array.isArray(body[bodyKey])) {
              parseBody[bodyKey] = await Promise.all(
                (body[bodyKey] as Array<Record<string, string | File>>).map(
                  async ele => {
                    // console.log(bodyKey, ele)
                    const result = await this.getBody(ele, service);
                    // console.log('result', bodyKey, result);
                    return result;
                  }
                )
              );
              return;
            }
            // console.log('tets', body[bodyKey], body[bodyKey] instanceof File)
            if (body[bodyKey] instanceof File) {
              // console.log('type file', body[bodyKey])
              return await service.save(body[bodyKey]).then((res: any) => {
                const data = res.data;
                const result = {
                  originalName: data.originalname,
                  path: data.path,
                  savedName: data.filename
                };
                // // console.log(result);
                parseBody[bodyKey] = result;
              });
            }
          })
        );
      }
      return parseBody;
    },

    async get(criteriaName: string) {
      const { data } = await configedRequest.get<any>(`${url}/${criteriaName}`);
      return data;
    },

    async put(criteriaId: string, body: any) {
      let objData = {};
      const test = body.criteria_label.sections.map((section: any) => {
        const keyObject = Object.keys(section.sectionData);
        keyObject.map(async (keys: any) => {
          if (section.sectionData[keys].file) {
            section.sectionData[keys].file.map((saveFile: any) => {
              if (saveFile instanceof File) {
                return fileService
                  .save(saveFile)
                  .then((res: any) => {
                    section.sectionData[keys].file = [
                      ...section.sectionData[keys].file,
                      {
                        originalName: res.data.originalname,
                        path: res.data.path,
                        savedName: res.data.filename
                      }
                    ];

                    section.sectionData[keys].file = section.sectionData[
                      keys
                    ].file.filter((p: any) => Object.keys(p).length);
                  })
                  .then(async () => {
                    objData = {
                      ...body,
                      criteria_label: {
                        sections: [...test]
                      }
                    };
                    const { data } = await configedRequest.put(
                      `${url}/${criteriaId}`,
                      objData
                    );
                    return data;
                  });
              }
            });
          }
        });
        return {
          ...section
        };
      });

      const { data } = await configedRequest.put(`${url}/${criteriaId}`, body);
      return data;
    },
    async getCategory(level: string, userLicense?: any) {
      const query = {
        level: level,
        userLicense: userLicense
      };

      const { data } = await configedRequest.get(
        `${applyLicenseUrl}/category/${level}`,
        {
          params: query
        }
      );
      return data;
    },
    async changeStatus(
      userLicenseId: string,
      criteriaName: string,
      body: ChangeApplylicenseStatus
    ) {
      const { data } = await configedRequest.put(
        `${applyLicenseUrl}/${userLicenseId}/${criteriaName}`,
        body
      );
      return data;
    },
    async saveUserLicense(licenseInfo: SaveUserLicense) {
      const { data } = await configedRequest.post("license/user", licenseInfo);
      return data;
    },
    async getAppliedUserLicense(query: SearchPaginate) {
      const { data } = await configedRequest.get(`/license/user/applied`, {
        params: query
      });
      return data;
    },
    async getApproveUserLicense(query: SearchPaginate) {
      const { data } = await configedRequest.get(`/license/user/approved`, {
        params: query
      });
      return data;
    },
    async getUserLicenseById(userLicenseId: string) {
      const { data } = await configedRequest.get(
        `/license/user/${userLicenseId}`
      );
      return data;
    },
    async submitLicense(licenseInfo: SaveUserLicense) {
      const { data } = await configedRequest.put(`/license/user`, licenseInfo);
      return data;
    },
    async saveData(criteriaName: string, body: SaveApplyLicense) {
      const license_criteria = await this.getBody(
        body.license_criteria,
        fileService
      );

      const uploadBody = { ...body, license_criteria };
      const user = await configedRequest
        .get<ResponseSuccess<any>>(`license/user`)

      const periodStart = user.data?.data?.license_period?.period_start
      const periodEnd = user.data?.data?.license_period?.period_end

      if (periodStart && periodEnd) {
        uploadBody.period_start = periodStart
        uploadBody.period_end = periodEnd
      }

      const { data } = await configedRequest.post(
        `${applyLicenseUrl}/save/${criteriaName}`,
        uploadBody
      );
      return data;
    },
    async getData(criteriaName: string, userLicense?: string) {
      const query = {
        criteriaName: criteriaName,
        userLicense: userLicense
      };
      const { data } = await configedRequest.get(
        `${applyLicenseUrl}/${criteriaName}`,
        {
          params: query
        }
      );
      return data;
    },
    async getAccountData(userLicenseId: string, criteriaName: string) {
      const { data } = await configedRequest.get(
        `${applyLicenseUrl}/${userLicenseId}/${criteriaName}`
      );
      return data;
    },
    async getFirstSaveCategory(categorySlug: string) {
      const { data } = await configedRequest.get(
        `${applyLicenseUrl}/${categorySlug}/status`
      );
      return data;
    },
    async getAcademyDetailById(userLicenseId: string) {
      const { data } = await configedRequest.get(
        `/apply-license/${userLicenseId}/all`
      );
      return data;
    },

    async getBasicInfoById(userLicenseId: string) {
      const { data } = await configedRequest.get(`/profile/${userLicenseId}`);
      return data;
    },

    async submitApproveUserInfo(userLicenseId: string | number,bodySubmit: any){
      console.log('Submit',userLicenseId,bodySubmit);
      const { data } = await configedRequest.put(
        `/profile/${userLicenseId}/status`,bodySubmit
      );

      console.log('Submit response',data);
      // return;
      return data;
    },
    async deleteUserLicense(userLicenseId: number) {
      const { data } = await configedRequest.post(
        `/license/user/deleteCriteria/${userLicenseId}`,
        {
          params: userLicenseId
        }
      );
      return data;
    }
  };
}
