export interface ResponseSuccess<T = any> {
  success: boolean;
  message: string;
  data: T;
}

export interface SigninResponse {
  account: ResponseSuccess<Account>;
}

export interface UpdateLicenseStatus {
  status: ELicenseStatus;
  comment?: string;
}

export interface Account {
  token: Token;
  user: AuthUser;
}

interface Token {
  access_token: string;
  token_type: string;
  expires_in: number | string;
}

export interface AuthUser {
  account_owner_id: number;
  account_owner_firstname: string;
  account_owner_lastname: string;
  account_owner_email: string;
  account_roles: any[];
}

export interface Account extends BasicData {
  account_owner_id: number;
  roles?: Roles;
  account_owner_firstname: string;
  account_owner_lastname: string;
  account_owner_email: string;
  account_owner_password: string;
  account_owner_tel: string;
  subscription_start_date?: Date;
  subscription_end_date?: Date;
  account_gender: string;
}

export interface LicenseLevel extends BasicData {
  id: number;
  license_slug: ELevelSlug;
  license_name: string;
  license_description: string;
  deleted?: boolean;
}

export interface NotificationMessage extends BasicData {
  message: NotificationMessageDetail;
  isRead: boolean;
  _id: string;
  information: {
    category_name: string;
    category_slug: string;
    userlicense_id: string;
  };
}

export interface NotificationMessageDetail {
  message: string;
  message_th: string;
}

export interface SearchPaginate {
  page?: number;
  size?: number;
  name?: string;
}

export interface PaginateResult<T = any> {
  page: number;
  size: number;
  perPage?: number;
  total: number;
  lastPage: number;
  data: T[];
  currentPage?: number;
}

export interface DefaultResponse<T = any> {
  message: number;
  success: boolean;
  data: T;
}

export interface SearchCategory extends SearchPaginate {
  category_name?: string;
}

export interface Roles extends BasicData {
  id: number;
  roles_code: string;
  roles_name: string;
  roles_description: string;
  roles_permission?: RolesPermission;
  deleted?: boolean;
  active: boolean;
}

interface RolesPermission extends BasicData {
  id: number;
  code: string;
  menu: string;
  action: string | string[];
  roles: Roles;
}

export interface BasicData {
  active: boolean;
  created_at: Date;
  created_by: string;
  updated_at: Date;
  updated_by: string;
}

export interface ResponseFailed {
  success: boolean;
  message: string;
  error_code: number;
  data?: object;
}

/* Authentication */
export interface SignUp {
  roles_code: string;
  account_owner_firstname: string;
  account_owner_lastname: string;
  account_owner_email: string;
  account_owner_password: string;
  account_owner_tel?: string;
  account_gender?: string;
  created_by: string;
}

export interface SignIn {
  email: string;
  password: string;
}

export interface ResetPassword {
  account_owner_id: number;
  current_password: string;
  new_password: string;
}
/* End Authentication */

/* Authentication Codium API */
export interface SignInCodium {
  username: string;
  password: string;
}

export interface RefreshTokenCodium {
  token: string;
}

export interface ResponseCodium {
  firstname: string;
  lastname: string;
  username: string;
  role: string;
}
/* End Authentication Codium API */

/* Account */
export interface UpdateAccount {
  account_owner_id: number;
  roles_code?: string;
  account_owner_firstname?: string;
  account_owner_lastname?: string;
  account_owner_email?: string;
  account_owner_tel?: string;
  account_gender?: string;
  updated_by: string;
}

interface UpdateUser {
  account_id: string;
  account_name: string;
}

export interface Activity {
  category: EActivityCategory;
  activity_type: string;
  request_ip: string;
  deleted?: boolean;
  active?: boolean;
  created_at?: Date;
  updated_at?: Date;
  updated_by?: UpdateUser;
}
export interface PaginationsInterface {
  page?: number;
  first_item?: number;
  last_item?: number;
  total?: number;
  perpage?: number;
  last_page?: number;
}
export interface UpdateRoleAccount {
  account_owner_id?: number;
  updated_by: string;
}
/* End Account */

export enum EActivityCategory {
  AUTHENTICATION = "AUTHENTICATION",
  DOCUMENT = "DOCUMENT",
  LICENSE = "LICENSE",
  PERIOD = "PERIOD"
}

/* Role */
export enum ERoleCode {
  ADMIN = "R001",
  CRITERIA_APPROVAL = "R002",
  LICENSE_APPROVAL = "R003",
  REPORTER = "R004",
  MEMBER = "R005"
}

export enum ERoleName {
  R001 = "ADMIN",
  R002 = "CRITERIA_APPROVAL",
  R003 = "LICENSE_APPROVAL",
  R004 = "REPORTER",
  R005 = "MEMBER"
}

export enum ERolePermissionCode {
  DASHBOARD = "P1",
  ACADEMY = "P2",
  ACTIVITY = "P3",
  USER = "P4",
  SETTING = "P5",
  DOCUMENT_APPROVES = "P6",
  LICENSE_APPROVES = "P7"
}

export enum ERolePermissionMenu {
  P1 = "DASHBOARD",
  P2 = "ACADEMY",
  P3 = "ACTIVITY",
  P4 = "USER",
  P5 = "SETTING",
  P6 = "DOCUMENT_APPROVES",
  P7 = "LICENSE_APPROVES"
}

export enum EAction {
  ENABLE = "ENABLE",
  DISABLE = "DISABLE"
}

export enum EAppliedStatus {
  APPLIED = "APPLIED",
  APPROVE = "APPROVE",
  REQUIRED = "REQUIRED",
  REJECT = "REJECTED",
  RESUBMITTED = "RESUBMITTED"
}

export interface ChangeApplylicenseStatus {
  status: EAppliedStatus;
  comment?: string;
}

export interface NotificationSave {
  title: string;
  completed: number;
  total: number;
}
export type TRolePermission = {
  code: ERolePermissionCode;
  menu: ERolePermissionMenu;
  action: [EAction];
  created_by: string;
};

export interface CreateRole {
  roles_code: ERoleCode;
  roles_name: ERoleName;
  roles_description: string;
  // roles_permissions: [TRolePermission]
  deleted: string;
  created_by: string;
}

export type CreateRolePermissionWithRoleCode = TRolePermission;

export interface UpdateRole {
  roles_code?: string;
  roles_name: string;
  roles_description?: string;
  roles_permissions?: [TRolePermission];
  deleted?: string;
  updated_by: string;
}
/* End Role */

/* License Level */
export enum ELevelSlug {
  BRONZE = "BRONZE",
  SILVER = "SILVER",
  GOLD = "GOLD"
}

export enum ELicenseStatus {
  APPLIED = "APPLIED",
  SUBMITTED = "SUBMITTED",
  APPROVE_PENDING = "APPROVE_PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED"
}

export interface SavePeriod {
  period_start: string;
  period_end: string;
  updated_by?: string;
}

export interface CreateLevel {
  license_slug: ELevelSlug | null;
  license_name: string | null;
  license_description: string | null;
  active: boolean;
}

export interface UpdateLevel {
  level_id: number;
  license_slug?: ELevelSlug;
  license_name?: string;
  license_description?: string;
  updated_by: string;
}
/* End License Level */

/* Apply for License */
export enum EStatusApplyLicense {
  SUBMIT = "SUBMIT",
  VERIFY = "VERIFY",
  REQUIRE_INFORMATION = "REQUIRE MORE INFORMATION",
  APPROVED_REJECT = "APPROVED/REJECT"
}
/* End Apply for License */

export interface UpdateCriteria {
  criteria_name: string;
  criteria_label: {};
  license_level_id: number;
  category_id: number;
  active: boolean;
}

export interface FileLink {
  originalName: string;
  savedName: string;
  path: string;
}

export interface SaveApplyLicense {
  fill_required: boolean;
  license_criteria: any;
  license_level: string;
  period_start: string;
  period_end: string;
}

export interface SaveUserLicense {
  license_level: string;
  period_start: string;
  period_end: string;
}

export interface SearchAccountParams {
  name?: string;
  gener?: string;
}

export interface CodiumStaff {
  active: boolean;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
  account_id: string;
  account_informations: AccountInformation;
  account_region_citizen: string;
  account_region_current: string;
  account_specifications: string;
  account_email: string;
  is_loggedin: boolean;
  account_email_verified: boolean;
  account_email_verified_token: string;
  account_email_verified_expires: string;
  account_password_verified: boolean;
  account_password_verified_token: string;
  account_password_verified_expires: string;
  account_type: number;
  account_subscription_login_at: string;
  account_subscription_logout_at: string;
  account_published: boolean;
  deleted: boolean;
  ac_codium_informations: CodiumInformation;
}

interface CodiumInformation {
  active: boolean;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
  id: string;
  ac_codium_id: string;
  ac_codium_fifa_id: string;
  ac_codium_birth_date: string;
  ac_codium_notification_email: string;
  ac_codium_id_card_expiry_date: string;
  ac_codium_position: string;
  account_id: string;
  deleted: boolean;
}

interface AccountInformation {
  active: boolean;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
  id: string;
  account_information_code_personnel: string;
  account_information_citizen_id: string;
  account_information_passport_id: string;
  account_information_type_personnel: string;
  account_information_firstname_th: string;
  account_information_lastname_th: string;
  account_information_firstname_en: string;
  account_information_lastname_en: string;
  account_information_nickname: string;
  account_information_nickname_en: string;
  account_information_blood_type: string;
  account_information_dob: string;
  account_information_religion: string;
  account_information_race: string;
  account_information_nationality: string;
  account_information_nationality_en: string;
  account_information_phone: string;
  account_information_gender: number;
  account_information_height: number;
  account_information_weight: string;
  account_information_biography_th: string;
  account_information_biography_en: string;
  account_information_hand_skill: string;
  account_information_feet_skill: string;
  deleted: boolean;
}

export interface AccountList {
  firstname: string;
  lastname: string;
  // gender: string;
  position: number;
  text: string;
  value: string;
}

export interface CategoryBody {
  category_name: string;
  category_description: string;
  sequence_no: number;
}

// export enum ELicense {
//   DOCUMENT = "DOCUMENT",
//   LICENSE = "LICENSE",
//   PERIOD = "PERIOD"
// }

export interface Dashboard {
  //   category: EDashboardCategory;
  year: string;
  licenseOfYear: string;
}

export interface PeriodFilter {
  year: number;
}
